import React from 'react'

export default function componente1() {
  return (
    <div>
              
      <title>TÍtulo</title>

      <header>

      </header>

      <nav>

      </nav>
              
      <section id="contenedor_zona_1">

        <p>Hola Juan</p>

      </section>

      <section id="contenedor_zona_2">

      </section>

      <footer>

      </footer>

    </div>
  )
}


